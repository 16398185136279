/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_content_delivery_bucket: "ktppsea-hostingbucket-prod",
  aws_content_delivery_bucket_region: "ap-southeast-1",
  aws_content_delivery_url:
    "http://ktppsea-hostingbucket-prod.s3-website-ap-southeast-1.amazonaws.com",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:10ee9d98-2108-4efb-9768-6a4b99dd6079",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_TBfS3cBjT",
  aws_user_pools_web_client_id: "40h9g3ud31v1dihd9am587aah9",
  oauth: {},
  bucket: "ktppsea-resources-prod",
  region: "ap-southeast-1",
};

export default awsmobile;
