import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Amplify, { Auth } from "aws-amplify";
import config from "@/aws-exports";
Amplify.configure(config);
Vue.use(VueAxios, axios);
const client = axios.create({
  baseURL: "https://8lk82s0ysd.execute-api.ap-southeast-1.amazonaws.com/prod",
  json: true,
});

export default {
  async getShortLink(url) {
    return axios
      .get(`https://tinyurl.com/api-create.php?url=${url}`)
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async getVisit() {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: "/visit",
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async addVisit() {
    return client({
      method: "post",
      url: "/visit",
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async getUser(user) {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: user ? `/user?email=${user}` : "/user",
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async resendPassword(user) {
    return client({
      method: "get",
      url: `/user/resend?email=${user}`,
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async resetPassword(user) {
    return client({
      method: "get",
      url: `/user/reset?email=${user}`,
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async adminInit() {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: "/admin/init",
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error;
      });
  },
  async registerUser(params) {
    var session = await Auth.currentSession();
    return client({
      method: "post",
      url: "/user",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async registerUserWithList(params) {
    var session = await Auth.currentSession();
    return client({
      method: "post",
      url: "/user/list",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async updateUser(params) {
    var session = await Auth.currentSession();
    return client({
      method: "put",
      url: "/user",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error;
      });
  },
  async deleteUser(params) {
    var session = await Auth.currentSession();
    return client({
      method: "delete",
      url: "/user",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error;
      });
  },

  // Permission
  async getRolePermission(role) {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: role ? `/role?roleId=${role}` : "/role",
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async addRolePermission(param) {
    var session = await Auth.currentSession();
    return client({
      method: "post",
      url: "/role",
      data: param,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async updateRolePermission(param) {
    var session = await Auth.currentSession();
    return client({
      method: "put",
      url: "/role",
      data: param,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async deleteRolePermission(role) {
    var session = await Auth.currentSession();
    return client({
      method: "delete",
      url: role ? `/role?roleId=${role}` : "/role",
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async checkRoleDelete(role) {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: `/role/check?roleId=${role}`,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req.data;
      })
      .catch((error) => {
        return error.response;
      });
  },

  //////////////////////// File ////////////////////////
  async getFiles(params) {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: `/file?preceptoryId=${params.preceptoryId}&type=${
        params.type
      }&folderName=${encodeURIComponent(params.folderName)}`,
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
  // PreceptoryId is a list of preceptory Ids to filter
  async getRecentFiles(preceptoryId, count = 10) {
    var session = await Auth.currentSession();
    var url = `file/all?num=${count}`;
    preceptoryId.forEach((item) => {
      url = url + "&preceptoryId=" + item;
    });
    return client({
      method: "get",
      url: url,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async createFile(params) {
    var session = await Auth.currentSession();
    return client({
      method: "post",
      url: "/file",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async updateFile(params) {
    var session = await Auth.currentSession();
    return client({
      method: "put",
      url: "/file",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },

  async deleteFile(params) {
    var session = await Auth.currentSession();
    return client({
      method: "delete",
      url: `/file?preceptoryId=${params.preceptoryId}&type=${params.type}&folderName=${params.folderName}&fileName=${params.fileName}&fileId=${params.fileId}&modifiedBy=${params.modifiedBy}`,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },

  //////////////////////// Folder ////////////////////////
  async getFolder(params) {
    var session = await Auth.currentSession();
    return client({
      method: "get",
      url: `/folder?preceptoryId=${params.preceptoryId}&type=${
        params.type
      }&parentDir=${encodeURIComponent(
        params.parentDir
      )}&folderName=${encodeURIComponent(params.folderName)}`,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async createFolder(params) {
    var session = await Auth.currentSession();
    return client({
      method: "post",
      url: "/folder",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async updateFolder(params) {
    var session = await Auth.currentSession();
    return client({
      method: "put",
      url: "/folder",
      data: params,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
  async deleteFolder(params) {
    var session = await Auth.currentSession();
    return client({
      method: "delete",
      url: `/folder?preceptoryId=${params.preceptoryId}&type=${
        params.type
      }&folderName=${encodeURIComponent(params.folderName)}&recursive=${
        params.recursive
      }&fileId=${encodeURIComponent(params.fileId)}&modifiedBy=${
        params.modifiedBy
      }`,
      headers: { Authorization: session.idToken.jwtToken },
    })
      .then((req) => {
        return req;
      })
      .catch((error) => {
        return error.response;
      });
  },
};
