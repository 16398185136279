import Vue from "vue";
import App from "./App.vue";
import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import awsconfig from "./aws-exports";
import store from "./store";
import router from "./router";
import "./index.css";
import axios from "axios";
import VueAxios from "vue-axios";
import VueProgressBar from "vue-progressbar";
import { Auth } from "aws-amplify";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserSecret,
  faUserEdit,
  faTrashAlt,
  faEllipsisH,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFileCsv,
  faFileExcel,
  faImages,
  faTimes,
  faFileAlt,
  faScroll,
  faHandHoldingHeart,
  faChartArea,
  faDownload,
  faFolderOpen,
  faCheck,
  faFolderPlus,
  faFileUpload,
  faPhotoVideo,
  faFilm,
  faVideo,
  faTh,
  faList,
  faUserPlus,
  faIdCard,
  faFile,
  faEdit,
  faMusic,
  faAngleRight,
  faAngleLeft,
  faArrowRight,
  faCaretDown,
  faPen,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import lineClamp from "vue-line-clamp";
import VueSimpleAlert from "vue-simple-alert";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
import VueScreenSize from "vue-screen-size";
import TextareaAutosize from "vue-textarea-autosize";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import "pdfjs-dist/build/pdf.worker.entry";
import VTooltip from "v-tooltip";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VueLoading from "vue-loading-overlay";
import VueConfetti from "vue-confetti";
import "vue-loading-overlay/dist/vue-loading.css";

Amplify.configure(awsconfig);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.component(
  "toast-message",
  require("./components/ToastNotification.vue").default
);

Vue.use(lineClamp, {
  // plugin options
});

Vue.use(VModal);
Vue.use(VueSimpleAlert, {
  customClass: {
    confirmButton: "w-2/5",
    cancelButton: "w-2/5",
  },
});
Vue.use(VTooltip);

library.add(
  faUserSecret,
  faUserEdit,
  faTrashAlt,
  faEllipsisH,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFileCsv,
  faFileExcel,
  faImages,
  faTimes,
  faFileAlt,
  faScroll,
  faHandHoldingHeart,
  faChartArea,
  faDownload,
  faFolderOpen,
  faCheck,
  faFolderPlus,
  faFileUpload,
  faPhotoVideo,
  faFilm,
  faVideo,
  faTh,
  faList,
  faUserPlus,
  faIdCard,
  faFile,
  faEdit,
  faMusic,
  faAngleRight,
  faAngleLeft,
  faArrowRight,
  faCaretDown,
  faPen,
  faLink
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueProgressBar, {
  color: "rgb(199, 48, 194)",
  failedColor: "red",
  height: "2px",
});
Vue.use(VueConfetti);
Vue.use(VueScreenSize);

Vue.use(VueConfetti);

Vue.use(VueMoment, {
  moment,
});

VuejsDatatableFactory.useDefaultType(false).registerTableType(
  "datatable",
  (tableType) =>
    tableType.mergeSettings({
      table: {
        class:
          "table table-hover table-striped text-center border-2 w-full border-gray-300 bg-white",
        row: {
          class: "h-9",
        },
        sorting: {
          sortAsc: `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-full inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
        </svg>
        `,
          sortDesc: `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-full inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
        </svg>`,
          sortNone: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-full inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
        </svg>`,
        },
      },

      pager: {
        classes: {
          pager: "pagination text-right items-center mb-3",
          selected: "active inline text-red-400 font-bold text-lg 2xl:text-2xl",
          li: "inline text-gray-500 text-base 2xl:text-lg mx-2  px-1",
        },
      },
    })
);

Vue.use(VuejsDatatableFactory);
Vue.use(TextareaAutosize);
Vue.use(VueFilterDateFormat);
Vue.prototype.$vuescrollConfig = {
  bar: {
    background: "#cecece",
  },
};

Vue.use(VueLoading);

Auth.currentAuthenticatedUser({ bypassCache: true })
  .then((user) => {
    store.commit("security/setAuth", user);
    var role = {};
    role = JSON.parse(user.attributes["custom:role"]);
    store
      .dispatch("security/GET_USER_ROLE", {
        role: role.roleId,
      })
      .then((res) => {
        store.commit("security/setPermission", {
          role: role,
          permission: res.data,
        });
        new Vue({
          store,
          router,
          render: (h) => h(App),
        }).$mount("#app");
      });
  })
  .catch(() => {
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  });
